//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
  name: 'PrimaryNavigation',
  data() {
    return {
      /* menuOpened: false, */
      activeChilds: [],
      isScrolled: true,
      isLangOpened: false,
      isOffset: false,
    }
  },
  computed: {
    ...mapState({
      sideLinks: (state) => state.links,
      menuOpened: (state) => state.isMenuOpened || state.isLoginOpened,
    }),
    profilePictureUrl(){
      if(this.$auth.user) {
        return this.$auth.user?.profile_picture?.publicUrl ?? `https://ui-avatars.com/api/?name=${this.$auth.user.firstname}+${this.$auth.user.lastname}`
      }
      return null
    }
  },
  watch: {
    menuOpened: {
      handler() {
        if (this.menuOpened) {
          document.body.classList.add('js-kill-scroll')
        } else {
          document.body.classList.remove('js-kill-scroll')
        }
      },
    },
    $route: {
      handler() {
        this.$store.commit('SET_MENU', false)
        this.$store.commit('SET_LOGIN', false)
        this.$store.commit('SET_PROFILE', false)
        /* this.menuOpened = false */
      },
    },
  },
  mounted() {
    this.lastScrollPosition = window.pageYOffset
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('scroll', this.showBg)
    const viewportMeta = document.createElement('meta')
    viewportMeta.name = 'viewport'
    viewportMeta.content = 'width=device-width, initial-scale=1'
    document.head.appendChild(viewportMeta)
    /* this.sideLinks?.navigation[0]?.children?.forEach((obj, index) => {
      this.activeChilds.push(index)
    }) */
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('scroll', this.showBg)
  },
  methods: {
    handleScroll() {
      const OFFSET = 60
      if (window.pageYOffset < 0) {
        return
      }
      if (Math.abs(window.pageYOffset - this.lastScrollPosition) < OFFSET) {
        return
      }
      this.isScrolled = window.pageYOffset < this.lastScrollPosition
      this.lastScrollPosition = window.pageYOffset
    },
    showBg() {
      window.scrollY > 240 ? (this.isOffset = true) : (this.isOffset = false)
    },
    toggleChildren(index) {
      const foundIndex = this.activeChilds.findIndex((i) => i === index)
      if (foundIndex === -1) {
        this.activeChilds.push(index)
      } else {
        this.activeChilds.splice(foundIndex, 1)
      }
    },
    toggleMenu(boolean) {
      this.$store.commit('SET_MENU', boolean)
    },
    toggleLogin() {
      if(!this.$auth.loggedIn) {
        this.$store.commit('SET_LOGIN', true)
        this.$store.commit('SET_PROFILE', false)
      } else {
        this.$store.commit('SET_PROFILE', true)
        this.$store.commit('SET_LOGIN', false)
      }
    },
  },
}
